import { createAsyncThunk, current } from '@reduxjs/toolkit';
import axios from 'axios';

export const initStateCheckRecoveryPassword = {
  checkRecoveryPassword: { data: null, loading: false, error: null },
};

export const checkRecoveryPassword = createAsyncThunk('user/checkRecoveryPassword', async ({ uidb64, token }, { rejectWithValue, fulfillWithValue }) => {
  return await axios
    .get(`${process.env.REACT_APP_SERVER_API}/auth/recovery/${uidb64}/${token}`)
    .then((res) => {
      return fulfillWithValue(res.data);
    })
    .catch((res) => {
      return rejectWithValue(res.response.data);
    });
});

export const reducerCheckRecoveryPassword = {
  [checkRecoveryPassword.pending]: (state) => {
    state.checkRecoveryPassword.loading = true;
    state.checkRecoveryPassword.error = null;
  },
  [checkRecoveryPassword.fulfilled]: (state, action) => {
    state.checkRecoveryPassword.loading = false;
    state.checkRecoveryPassword.data = true;
    state.checkRecoveryPassword.error = null;
  },
  [checkRecoveryPassword.rejected]: (state, action) => {
    state.checkRecoveryPassword.loading = false;
    state.checkRecoveryPassword.error = action.payload;
  },
};
