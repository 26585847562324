import React, { useEffect, useState } from 'react';

import Avatar from '../../submodule/img/01.jpg';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { recoveryPassword } from '../../redux/actions/user/recoveryPassword';
import Loading from '../../components/Loading/Loading';
import { resetRecoveryPassword } from '../../redux/slices/user.slice';
function PasswordRecoveryIndex() {
  const form = useForm();
  const dispatch = useDispatch();
  const [recoverySuccess, setRecoverySuccess] = useState(false);
  const {
    recoveryPassword: { data: recoveryPasswordData, error: recoveryPasswordError, loading: recoveryPasswordLoading },
  } = useSelector((state) => state.user);
  const onSubmit = (data) => {
    dispatch(recoveryPassword(data));
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (recoveryPasswordData) {
      setRecoverySuccess(true);
    }
  }, [recoveryPasswordData]);

  useEffect(() => {
    return () => {
      setRecoverySuccess(false);
      dispatch(resetRecoveryPassword());
    };
  }, []);

  return (
    <div class="flex flex-col min-h-screen">
      {recoverySuccess ? (
        <div class="flex flex-1 flex-col w-full mx-auto gap-2 justify-center items-center max-w-[350px] w-full px-4">
          <svg xmlns="http://www.w3.org/2000/svg" width="54px" height="54px" viewBox="0 0 24 24" class="text-blue-500">
            <mask id="lineMdEmailCheck0">
              <g fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                <path stroke-dasharray="64" stroke-dashoffset="64" d="M4 5h16c0.55 0 1 0.45 1 1v12c0 0.55 -0.45 1 -1 1h-16c-0.55 0 -1 -0.45 -1 -1v-12c0 -0.55 0.45 -1 1 -1Z">
                  <animate fill="freeze" attributeName="stroke-dashoffset" dur="0.6s" values="64;0" />
                </path>
                <path stroke-dasharray="24" stroke-dashoffset="24" d="M3 6.5l9 5.5l9 -5.5">
                  <animate fill="freeze" attributeName="stroke-dashoffset" begin="0.6s" dur="0.2s" values="24;0" />
                </path>
                <path fill="#000" fill-opacity="0" stroke="none" d="M19 13c3.31 0 6 2.69 6 6c0 3.31 -2.69 6 -6 6c-3.31 0 -6 -2.69 -6 -6c0 -3.31 2.69 -6 6 -6Z">
                  <set fill="freeze" attributeName="fill-opacity" begin="0.8s" to="1" />
                </path>
                <path stroke-dasharray="10" stroke-dashoffset="10" d="M16 19l1.75 1.75l3.75 -3.75">
                  <animate fill="freeze" attributeName="stroke-dashoffset" begin="0.8s" dur="0.2s" values="10;0" />
                </path>
              </g>
            </mask>
            <rect width="24" height="24" fill="currentColor" mask="url(#lineMdEmailCheck0)" />
          </svg>
          <div class="text-xl text-center leading-7">Ссылка с восстановлением отправлена на почту</div>
          <div className="text-center text-gray-400">На указаный email отправлена ссылка для смены пароля. Перейдите по ней для дальнейшего этапа восстановления </div>
          <Button
            className="mt-4"
            onClick={() => {
              navigate('/login');
            }}>
            Назад
          </Button>
        </div>
      ) : (
        <div class="flex flex-1 flex-col w-full mx-auto gap-2 justify-center items-center max-w-[350px] w-full px-4">
          <div class="text-xl ">Восстановление пароля</div>
          <div className="text-center text-sm text-gray-400 mb-2">Пожалуйста, введите свой адрес электронной почты. Вы получите ссылку для создания нового пароля по электронной почте.</div>
          <div class="flex flex-col gap-1 w-full">
            <Input
              form={form}
              name="email"
              placeholder={'Email'}
              rules={{
                required: { value: true, message: '*Обязательное поле для заполнения' },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Неверный формат почты',
                },
              }}
              disabled={recoveryPasswordLoading}
            />
          </div>
          {recoveryPasswordError && <div className="text-red-400 text-sm">Аккаунт с такой почтой не найден</div>}
          <div class="mt-2 relative flex flex-col items-center justify-center">
            <Button disabled={recoveryPasswordLoading} onClick={form.handleSubmit(onSubmit)} className="relative w-[80px] h-[42px] mt-1">
              Восстановить
            </Button>
            <div className="mt-4 text-sm">
              <span className="text-gray-400"> Вспомнили пароль ?</span>&nbsp;
              <Link to="/login" className="font-bold">
                Войти
              </Link>
            </div>
            {recoveryPasswordLoading && <Loading className="absolute bottom-[-60px] left-1/2 transform -translate-x-1/2 " />}
          </div>{' '}
        </div>
      )}
    </div>
  );
}

export default PasswordRecoveryIndex;
