import React, { useEffect, useState } from 'react';

import Avatar from '../../submodule/img/01.jpg';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../redux/actions/user/loginUser';
import Loading from '../../components/Loading/Loading';
import { resetLoginUser } from '../../redux/slices/user.slice';
function LoginIndex() {
  const form = useForm();
  const dispatch = useDispatch();
  const {
    loginUser: { data: loginUserData, error: loginUserError, loading: loginUserLoading },
  } = useSelector((state) => state.user);
  const onSubmit = (data) => {
    dispatch(loginUser(data));
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (loginUserData) {
      localStorage.setItem('accessToken', loginUserData.access);
      localStorage.setItem('refreshToken', loginUserData.refresh);
      navigate('/');
    }
  }, [loginUserData]);
  useEffect(() => {
    return () => {
      dispatch(resetLoginUser());
    };
  }, []);

  return (
    <div class="flex flex-col min-h-screen">
      <div class="flex flex-1 flex-col w-full mx-auto gap-2 justify-center items-center max-w-[350px] w-full px-4">
        <div class="text-xl mb-1">Вход</div>
        <div class="flex flex-col gap-1 w-full">
          <Input
            form={form}
            name="email"
            placeholder={'Email'}
            rules={{
              required: { value: true, message: '*Обязательное поле для заполнения' },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Неверный формат почты',
              },
            }}
            disabled={loginUserLoading}
          />
        </div>
        <div class="flex flex-col gap-1 w-full">
          <Input form={form} type={'password'} name="password" placeholder={'Пароль'} disabled={loginUserLoading} />
        </div>
        <Link className="text-sm text-gray-400 underline underline-offset-4 self-start" to="/password-recovery">
          Забыли пароль?
        </Link>
        {loginUserError && <div className="text-red-400 text-sm">Неверный логин или пароль</div>}
        <div class="mt-2 relative">
          <Button disabled={loginUserLoading} onClick={form.handleSubmit(onSubmit)} className="relative w-[80px] h-[42px] mt-1">
            Войти
          </Button>
          {loginUserLoading && <Loading className="absolute bottom-[-60px] left-1/2 transform -translate-x-1/2 " />}
        </div>{' '}
      </div>
    </div>
  );
}

export default LoginIndex;
