import React, { useEffect, useState } from 'react';

import Avatar from '../../submodule/img/01.jpg';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { saveNewPassword } from '../../redux/actions/user/saveNewPassword';
import Loading from '../../components/Loading/Loading';
import { resetCheckRecoveryPassword, resetSaveNewPassword } from '../../redux/slices/user.slice';
import { checkRecoveryPassword } from '../../redux/actions/user/checkRecoveryPassword';
function NewPasswordIndex() {
  const form = useForm();
  const dispatch = useDispatch();
  const { uidb64, token } = useParams();

  const {
    saveNewPassword: { data: saveNewPasswordData, error: saveNewPasswordError, loading: saveNewPasswordLoading },
    checkRecoveryPassword: { data: checkRecoveryPasswordData, error: checkRecoveryPasswordError, loading: checkRecoveryPasswordLoading },
  } = useSelector((state) => state.user);

  const onSubmit = (data) => {
    dispatch(
      saveNewPassword({
        password: data.password,
        uidb64,
        token,
      }),
    );
  };
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(checkRecoveryPassword({ uidb64, token }));
    return () => {
      dispatch(resetSaveNewPassword());
      dispatch(resetCheckRecoveryPassword());
    };
  }, []);
  useEffect(() => {
    if (checkRecoveryPasswordError) {
      navigate('/password-recovery');
    }
  }, [checkRecoveryPasswordError]);

  return saveNewPasswordData ? (
    <div class="flex flex-1 flex-col w-full mx-auto gap-2 justify-center items-center max-w-[350px] w-full px-4">
      <svg xmlns="http://www.w3.org/2000/svg" width="54px" height="54px" viewBox="0 0 24 24" class="text-green-400">
        <path
          fill="currentColor"
          d="m10.6 16.6l7.05-7.05l-1.4-1.4l-5.65 5.65l-2.85-2.85l-1.4 1.4zM12 22q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22m0-2q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4T6.325 6.325T4 12t2.325 5.675T12 20m0-8"
        />
      </svg>
      <div class="text-xl text-center leading-7">Пароль успешно изменен</div>
      <div className="text-center text-gray-400">Перейдите на страницу входа, чтобы авторизироваться </div>
      <Button
        className="mt-4"
        onClick={() => {
          navigate('/login');
        }}>
        Войти
      </Button>
    </div>
  ) : checkRecoveryPasswordData ? (
    <div class="flex flex-col min-h-screen">
      <div class="flex flex-1 flex-col w-full mx-auto gap-2 justify-center items-center max-w-[350px] w-full px-4">
        <div class="text-xl mb-1">Новый пароль</div>
        <div class="flex flex-col gap-1 w-full">
          <Input form={form} type={'password'} name="password" placeholder={'Новый пароль'} disabled={saveNewPasswordLoading} />
        </div>
        <div class="flex flex-col gap-1 w-full">
          <Input
            form={form}
            type={'password'}
            name="passwordRepeat"
            placeholder={'Повторите новый пароль'}
            disabled={saveNewPasswordLoading}
            rules={{ required: { value: true, message: '*Обязательное поле для заполнения' }, validate: (value) => value === form.watch('password') || 'Пароли не совпадают' }}
          />
        </div>
        {saveNewPasswordError && <div className="text-red-400 text-sm text-center">{saveNewPasswordError?.password?.[0] ?? 'Произошла непредвиденная ошибка'}</div>}
        <div class="mt-2 relative">
          <Button disabled={saveNewPasswordLoading} onClick={form.handleSubmit(onSubmit)} className="relative w-[80px] h-[42px] mt-1">
            Сохранить пароль
          </Button>
          {saveNewPasswordLoading && <Loading className="absolute bottom-[-60px] left-1/2 transform -translate-x-1/2 " />}
        </div>{' '}
      </div>
    </div>
  ) : (
    <></>
  );
}

export default NewPasswordIndex;
