import React from 'react';
import clsx from 'clsx';
export default function Input({ error, disabled, rules = { required: { value: true, message: '*Обязательное поле для заполнения' } }, placeholder, form, name, type, className }) {
  const errorForm = form?.formState?.errors?.[name];

  return (
    <>
      <input
        class={clsx(
          `w-full block w-full rounded-md border border-1 border-gray-300 focus:border-button px-3.5 py-2 text-gray-900 shadow-sm   placeholder:text-gray-400  focus:outline-none sm:text-sm sm:leading-6`,
          className,
          (errorForm || error) && 'border-red-300 focus:border-red-500 placeholder:text-red-400',
        )}
        disabled={disabled}
        autoComplete="off"
        data-1p-ignore
        data-lpignore="true"
        name={name}
        type={type}
        placeholder={placeholder}
        {...form?.register(name, rules)}
      />
      {errorForm && <div className="text-sm text-red-400 mt-1">{errorForm?.message}</div>}
      {error && <div className="text-sm text-red-400 mt-1">{error}</div>}
    </>
  );
}
